import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { createBrowserHistory } from 'history'
import configureStore from './common/redux/store'
import Root from './common/layouts/Root'


const preloadedState = window.__PRELOADED_STATE__
// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();
const store = configureStore(history, preloadedState);


ReactDOM.render(
  <React.Fragment>
    <Root store={store} history={history} />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
