import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline';
import NotFoundRouteView from '../../views/NotFoundRouteView'
import HomeView from '../../views/HomeView'
import Home2View from '../../views/Home2View'
import Home3View from '../../views/Home3View'
import Home4View from '../../views/Home4View'
import Home5View from '../../views/Home5View'
import Home6View from '../../views/Home6View'
import VersionSPView from '../../views/HomeSPView'
import Version2View from '../../views/Version2View'
import Version3View from '../../views/Version3View'
import LiveFlowView from '../../views/LiveFlowView'
import Preview from "../../views/PreView"

import { makeStyles } from '@material-ui/styles'

import config from '../../../config'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as BaseAction from '../../redux/actions/Base'

import Snackbar from '@material-ui/core/Snackbar'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import SpecialPageView from "../../views/SpecialPageView";
import SpecialLoginView from "../../views/SpecialLoginView";
import AirportView from "../../views/AirportView";
import GangkouView from "../../views/GangKouView";
import SchoolView from "../../views/SchoolView";


const propTypes = {
  children: PropTypes.object
}
const defaultProps = {
  children: {}
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    fontFamily: "微软雅黑"
  },
  notice: {
    textAlign: 'center'
  }
});

const AppLayout = (props) => {
  const classes = useStyles();
  const { error, modal } = props;
  /**
   *json 弹窗关闭
   * @returns null
   */
  const handleAlertClose = () => {
    const { ResetAlert, error } = props;
    if (error.onClose) {
      if (error.onClose()) {
        ResetAlert()
      }
    } else {
      ResetAlert()
    }
  };
  const handleModalClose = () => {
    const { CloseModal, modal } = props;
    if (modal.data && modal.data.cancelcallBack) {
      modal.data.cancelcallBack()
    }
    CloseModal();
  };
  const handleModalConfirm = () => {
    const { CloseModal, modal } = props;
    if (modal.data && modal.data.confirmCallBack) {
      if (modal.data.confirmCallBack()) {
        CloseModal();
      }
    } else {
      CloseModal();
    }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Helmet {...config.app} />
      <Snackbar
        className={classes.notice}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error && error.flag}
        onClose={handleAlertClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{error.message}</span>}
      />
      <Switch>
        <Route path={`${config.APP_BASE_URL}nopage`} component={NotFoundRouteView} />
        <Route path={`${config.APP_BASE_URL}live`} component={LiveFlowView} />
        <Route path={`${config.APP_BASE_URL}2`} component={Home2View} />
        <Route path={`${config.APP_BASE_URL}next`} component={Home4View} />
        <Route path={`${config.APP_BASE_URL}preview`} component={Preview} />
        <Route path={`${config.APP_BASE_URL}v2`} component={Version2View} />
        <Route path={`${config.APP_BASE_URL}v3`} component={Version3View} />
        <Route path={`${config.APP_BASE_URL}airport`} component={AirportView} />
        <Route path={`${config.APP_BASE_URL}school/:schoolId`} component={SchoolView} />
        <Route path={`${config.APP_BASE_URL}port/szjc`} component={AirportView} />
        <Route path={`${config.APP_BASE_URL}port/:portId`} component={GangkouView} />
        <Route path={`${config.APP_BASE_URL}sp/:locationId`} component={VersionSPView} />
        <Route path={`${config.APP_BASE_URL}splogin`} component={SpecialLoginView} />
        <Route path={`${config.APP_BASE_URL}sp`} component={SpecialPageView} />
        <Route path={`${config.APP_BASE_URL}`} component={Home6View} />
        <Route component={NotFoundRouteView} />
      </Switch>
      <Dialog
        open={!!(modal.status > 0 && modal.data)}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick={!(modal.data && modal.data.backClick)}
        disableEscapeKeyDown
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {(modal.data && modal.data.title) || ''}
        </DialogTitle>
        <DialogContent>
          {
            modal.data ?
              modal.data.body ?
                modal.data.body :
                <DialogContentText id="alert-dialog-slide-description">
                  {modal.data.contentText}
                </DialogContentText> :
              null

          }
        </DialogContent>
        <DialogActions>
          {
            modal.data && modal.data.cancel ? (
              <Button onTouchTap={handleModalClose} color="default">
                {modal.data.cancel}
              </Button>
            ) : null
          }
          {
            modal.data && modal.data.confirm !== false ? (
              <Button onTouchTap={handleModalConfirm} color="primary">
                {modal.data.confirm || '确认'}
              </Button>
            ) : null
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

AppLayout.propTypes = propTypes
AppLayout.defaultProps = defaultProps

export default connect(
  state => (
    {
      error: state.error.toJS(),
      modal: state.modal.toJS(),
    }
  ),
  dispatch => ({
    ...bindActionCreators(BaseAction, dispatch)
  })
)(AppLayout)
// // <Route exact path="/:userId/:deviceId" component={DeviceDetail} />
