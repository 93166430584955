export default {
  API_VERSION :process.env.REACT_APP_API_VERSION || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '1.0.0',
  API_ROOT: process.env.REACT_APP_API_ROOT || 'https://city-wind.bixuange.com',
  API_JSON_ROOT: process.env.REACT_APP_JSON_API_ROOT || 'https://files.wind.shengmaodou.com/',
  APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  DEV: true,
  app: {
    htmlAttributes: { lang: 'en' },
    title: '',
    titleTemplate: '%s',
    meta: [
      { name: 'description', content: '' },
    ],
    link:[
      // { type: 'text/css', rel: 'stylesheet', href: 'https://cdn.bootcss.com/font-awesome/4.7.0/css/font-awesome.css' }
    ]
  }
}
