import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createReducer from '../reducers'
// import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import callApi from '../middleware/callApi'

const configureStoreProd = (history = {}, preloadedState = {}) => createStore(
  createReducer(preloadedState, history),
  preloadedState,
  compose(
    applyMiddleware(thunk, callApi, routerMiddleware(history)),
  )
)

export default configureStoreProd
