import React, {useContext} from 'react'
import Helmet from 'react-helmet'

export default function AboutView(props) {
  console.log(props);
  return (
    <div className="view view__404">
      <Helmet title="Page not found - 404" />

      <h2>Page not found - 404</h2>
    </div>
  )
}
