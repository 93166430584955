import React, { Suspense } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
const HomeView = React.lazy(() => import('./HomeView'));
export default function (props) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <HomeView
        {...props}
      />
    </Suspense>
  )
}
