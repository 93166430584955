/**
 * Created by luzq on 16/5/11.
 */
import * as actionTypes from '../constants/ActionType'
import { CALL_API } from '../middleware/callApi'

/* 重置错误信息 */
export function SetAlert(param = {}) {
  return {
    ...param,
    type: actionTypes.RESET_ERROR_MESSAGE,
    flag: !!param.flag,
    message: param.message ? param.message : null,
    errorCode: param.errorCode ? param.errorCode : 0
  }
}
export function ResetAlert(param = {}) {
  return {
    ...param,
    type: actionTypes.RESET_ERROR,
  }
}
/* 设置弹窗状态 */
export function SetModal(param) {
  return {
    type: actionTypes.SET_MODAL,
    param
  }
}
/* 设置弹窗状态 */
export function CloseModal(param = {}) {
  return {
    type: actionTypes.CLOSE_MODAL,
    param
  }
}
export function WaitingModal() {
  return {
    type: actionTypes.WAITING_MODAL
  }
}
export function SetModalStatus(param = {}) {
  return {
    type: actionTypes.SET_MODAL_STATUS,
    param
  }
}
/* 设置等待图标状态 */
export function SetWaiting(param) {
  return {
    type: actionTypes.SET_WAITING,
    param
  }
}
export function SetWindowSize(param) {
  return {
    type: actionTypes.SET_WINDOW_SIZE,
    param
  }
}

/*
* 获取全部请求日志
* */
export const GetWindList = (param) => {
  return {
    type: actionTypes.GET_WIND_LIST_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WIND_LIST_REQUEST, actionTypes.GET_WIND_LIST_SUCCESS, actionTypes.GET_WIND_LIST_FAILURE],
      endpoint: `https://aqs.121.com.cn/wind/grid/v2/getWindGridList?key=b15d862ac168428e96cd8e59bc3a6cea`,
      schema: {}
    },
    param: { ...param, method: 'get' }
  }
}
export function GetWindListUpdate(param) {
  return {
    type: actionTypes.GET_WIND_LIST_UPDATE,
    param
  }
}
export function GetWindListReset(param) {
  return {
    type: actionTypes.GET_WIND_LIST_RESET,
    param
  }
}

/*
* 获取全部请求日志
* */
export const GetWindHistoryList = (param) => {
  return {
    type: actionTypes.GET_WIND_HISTORY_LIST_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WIND_HISTORY_LIST_REQUEST, actionTypes.GET_WIND_HISTORY_LIST_SUCCESS, actionTypes.GET_WIND_HISTORY_LIST_FAILURE],
      endpoint: `https://aqs.121.com.cn/wind/grid/v2/getHistoryWindGridList?key=b15d862ac168428e96cd8e59bc3a6cea`,
      schema: {}
    },
    param: { ...param, method: 'get' }
  }
}
export function GetWindHistoryListUpdate(param) {
  return {
    type: actionTypes.GET_WIND_HISTORY_LIST_UPDATE,
    param
  }
}
export function GetWindHistoryListReset(param) {
  return {
    type: actionTypes.GET_WIND_HISTORY_LIST_RESET,
    param
  }
}


/*
* 获取全部请求日志
* */
export const GetForecastWindList = (param) => {
  return {
    type: actionTypes.GET_WIND_DATE_LIST_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WIND_DATE_LIST_REQUEST, actionTypes.GET_WIND_DATE_LIST_SUCCESS, actionTypes.GET_WIND_DATE_LIST_FAILURE],
      endpoint: `https://aqs.121.com.cn/wind/grid/v2/safeWindForecastGridList?key=b15d862ac168428e96cd8e59bc3a6cea`,
      schema: {}
    },
    param: { ...param, method: 'get' }
  }
}
export function GetForecastWindListUpdate(param) {
  return {
    type: actionTypes.GET_WIND_DATE_LIST_UPDATE,
    param
  }
}
export function GetForecastWindListReset(param) {
  return {
    type: actionTypes.GET_WIND_DATE_LIST_RESET,
    param
  }
}


/*
* 获取机场高度
* */
export const GetAirportVerticalWind = (param) => {
  return {
    type: actionTypes.GET_AIRPORT_VERTICAL_LIST_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_AIRPORT_VERTICAL_LIST_REQUEST, actionTypes.GET_AIRPORT_VERTICAL_LIST_SUCCESS, actionTypes.GET_AIRPORT_VERTICAL_LIST_FAILURE],
      endpoint: `https://aqs.121.com.cn/wind/grid/getGridHeightData?key=b15d862ac168428e96cd8e59bc3a6cea&address=szjc`,
      schema: {}
    },
    param: { ...param, method: 'get' }
  }
}
export function GetAirportVerticalWindUpdate(param) {
  return {
    type: actionTypes.GET_AIRPORT_VERTICAL_LIST_UPDATE,
    param
  }
}
export function GetAirportVerticalWindReset(param) {
  return {
    type: actionTypes.GET_AIRPORT_VERTICAL_LIST_RESET,
    param
  }
}

/*
* 对接安全伞token
* */
export const GetToken = (param) => {
  return {
    type: actionTypes.GET_TOKEN_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_TOKEN_REQUEST, actionTypes.GET_TOKEN_SUCCESS, actionTypes.GET_TOKEN_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/token/getWebQueryToken?t=` + new Date().getTime(),
      schema: {}
    },
    param: { ...param, method: 'get' }
  }
}
export function GetTokenUpdate(param) {
  return {
    type: actionTypes.GET_TOKEN_UPDATE,
    param
  }
}
export function GetTokenReset(param) {
  return {
    type: actionTypes.GET_TOKEN_RESET,
    param
  }
}


/*
* 获取天气信息
* */
export const GetWeather = (param) => {
  return {
    type: actionTypes.GET_WEATHER_INFO_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WEATHER_INFO_REQUEST, actionTypes.GET_WEATHER_INFO_SUCCESS, actionTypes.GET_WEATHER_INFO_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/weather/getInnerWeatherInfo/${param.longitude}/${param.latitude}?t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetWeatherUpdate(param) {
  return {
    type: actionTypes.GET_WEATHER_INFO_UPDATE,
    param
  }
}
export function GetWeatherReset(param) {
  return {
    type: actionTypes.GET_WEATHER_INFO_RESET,
    param
  }
}


/*
* 获取天气信息
* */
export const GetWarning = (param) => {
  return {
    type: actionTypes.GET_WARNING_INFO_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WARNING_INFO_REQUEST, actionTypes.GET_WARNING_INFO_SUCCESS, actionTypes.GET_WARNING_INFO_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/warning/queryWarning/${param.longitude}/${param.latitude}?t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetWarningUpdate(param) {
  return {
    type: actionTypes.GET_WARNING_INFO_UPDATE,
    param
  }
}
export function GetWarningReset(param) {
  return {
    type: actionTypes.GET_WARNING_INFO_RESET,
    param
  }
}
/*
* 获取天气提示
* */
export const GetWeatherTip = (param) => {
  return {
    type: actionTypes.GET_WEATHER_TIP_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WEATHER_TIP_REQUEST, actionTypes.GET_WEATHER_TIP_SUCCESS, actionTypes.GET_WEATHER_TIP_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/weather/getWeatherHint?t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetWeatherTipUpdate(param) {
  return {
    type: actionTypes.GET_WEATHER_TIP_UPDATE,
    param
  }
}
export function GetWeatherTipReset(param) {
  return {
    type: actionTypes.GET_WEATHER_TIP_RESET,
    param
  }
}

/*
* 获取天气一句话
* */
export const GetWeatherOneWord = (param) => {
  return {
    type: actionTypes.GET_ONE_WORD_INFO_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_ONE_WORD_INFO_REQUEST, actionTypes.GET_ONE_WORD_INFO_SUCCESS, actionTypes.GET_ONE_WORD_INFO_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/warning/queryLdByDfMessage/${param.longitude}/${param.latitude}?t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetWeatherOneWordUpdate(param) {
  return {
    type: actionTypes.GET_ONE_WORD_INFO_UPDATE,
    param
  }
}
export function GetWeatherOneWordReset(param) {
  return {
    type: actionTypes.GET_ONE_WORD_INFO_RESET,
    param
  }
}

/*
* 获取天气指数
* */
export const GetWeatherIndex = (param) => {
  return {
    type: actionTypes.GET_WEATHER_INFO_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WEATHER_INDEX_REQUEST, actionTypes.GET_WEATHER_INDEX_SUCCESS, actionTypes.GET_WEATHER_INDEX_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/weather/getWeatherIndex/${param.longitude}/${param.latitude}?t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetWeatherIndexUpdate(param) {
  return {
    type: actionTypes.GET_WEATHER_INDEX_UPDATE,
    param
  }
}
export function GetWeatherIndexReset(param) {
  return {
    type: actionTypes.GET_WEATHER_INDEX_RESET,
    param
  }
}

/*
* 获取航空天气报文
* */
export const GetWeatherAirport = (param) => {
  return {
    type: actionTypes.GET_WEATHER_AIRPORT_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WEATHER_AIRPORT_REQUEST, actionTypes.GET_WEATHER_AIRPORT_SUCCESS, actionTypes.GET_WEATHER_AIRPORT_FAILURE],
      endpoint: `/data/metar/getMetarData?id=ZGSZ&t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get'
    }
  }
}
export function GetWeatherAirportUpdate(param) {
  return {
    type: actionTypes.GET_WEATHER_AIRPORT_UPDATE,
    param
  }
}
export function GetWeatherAirportReset(param) {
  return {
    type: actionTypes.GET_WEATHER_AIRPORT_RESET,
    param
  }
}




/*
* 获取航空天气报文
* */
export const GetWeatherWave = (param) => {
  return {
    type: actionTypes.GET_WEATHER_WAVE_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_WEATHER_WAVE_REQUEST, actionTypes.GET_WEATHER_WAVE_SUCCESS, actionTypes.GET_WEATHER_WAVE_FAILURE],
      endpoint: `https://aqs.121.com.cn/data/message/getWindRainMessage?t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetWeatherWaveUpdate(param) {
  return {
    type: actionTypes.GET_WEATHER_WAVE_UPDATE,
    param
  }
}
export function GetWeatherWaveReset(param) {
  return {
    type: actionTypes.GET_WEATHER_WAVE_RESET,
    param
  }
}



/*
* 获取gif信息
* */
export const GetAngleView = (param) => {
  /*
  * path: zone-1/level-0/east/segment_0-0_angledview/animation/animation_0-0
  * basename: gif
  * */
  return {
    type: actionTypes.GET_ANGLE_VIEW_IMAGE_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_ANGLE_VIEW_IMAGE_REQUEST, actionTypes.GET_ANGLE_VIEW_IMAGE_SUCCESS, actionTypes.GET_ANGLE_VIEW_IMAGE_FAILURE],
      endpoint: `/images/file/subFile?fileUrl=${param.path}&fileType=${param.basename || 'gif'}&t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetAngleViewUpdate(param) {
  return {
    type: actionTypes.GET_ANGLE_VIEW_IMAGE_UPDATE,
    param
  }
}
export function GetAngleViewReset(param) {
  return {
    type: actionTypes.GET_ANGLE_VIEW_IMAGE_RESET,
    param
  }
}


/*
* 获取俯视gif信息
* */
export const GetTopView = (param) => {
  return {
    type: actionTypes.GET_TOP_VIEW_IMAGE_LOAD,
    [CALL_API]: {
      types: [actionTypes.GET_TOP_VIEW_IMAGE_REQUEST, actionTypes.GET_TOP_VIEW_IMAGE_SUCCESS, actionTypes.GET_TOP_VIEW_IMAGE_FAILURE],
      endpoint: `/images/file/subFile?fileUrl=${param.path}&fileType=${param.basename || 'png'}&t=${new Date().getTime()}`,
      schema: {}
    },
    param: {
      ...param,
      method: 'get',
      headers: {
        token: param.token
      },
    }
  }
}
export function GetTopViewUpdate(param) {
  return {
    type: actionTypes.GET_TOP_VIEW_IMAGE_UPDATE,
    param
  }
}
export function GetTopViewReset(param) {
  return {
    type: actionTypes.GET_TOP_VIEW_IMAGE_RESET,
    param
  }
}
